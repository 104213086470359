<template>
  <div>

    <div>
      <el-row class="bgW">
      <el-col :span="18">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="门店类型" prop="type">
            <el-select
              v-model="search.type"
              class="w100"
              clearable
              placeholder="门店类型"
            >
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-switch
              v-model="value1"
              active-text="当年保有率 (默认显示当年保有率)"
              inactive-text="累计保有率"
              @change="changeCJTime">
            </el-switch>
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="6" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" class="mb10" size="mini" :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
      </el-row>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="tableData"
            size="small"
            max-height="550"
            border
            v-loading="loading"
            :span-method="objectSpanMethod"
            :cell-style="cellStyle"
            :header-cell-style="rowClass"
          >
            <ElTableColumn label="市场名称" prop="marketName" fixed />
            <ElTableColumn label="店面名称" prop="shopName" fixed />
           <!-- <ElTableColumn label="床位数" prop="bedNum" />
            <ElTableColumn label="转诊个数" prop="referralCount" />
            <ElTableColumn label="转诊金额" prop="referralMoney" />-->

			<!-- <ElTableColumn label="疤痕成交人数" prop="bahencj" /> -->
			<!-- <ElTableColumn label="项目人数" prop="xiangmupuj" /> -->
            <ElTableColumn label="项目普及率" :render-header="renderHeader">
                <template slot-scope="{ row }">
                  <div
                    class="hand"
                    @click="clickBtn(row,'popularize')"
                  >{{row.popularityRate}}</div>
                </template>
            </ElTableColumn>
			<!-- <ElTableColumn label="疤痕人数" prop="bahentwo" /> -->
            <ElTableColumn label="疤痕二次开发率" width='120' :render-header="renderHeader">
             <template slot-scope="{ row }">
                  <div
                    class="hand"
                    @click="clickBtn(row,'developRate')"
                  >{{row.developRate}}</div>
                </template>
            </ElTableColumn>
			<!-- <ElTableColumn label="功效成交人数" prop="gongxiaocj" /> -->
			<!-- <ElTableColumn label="功效满意人数" prop="gongxiaomy" /> -->
			<ElTableColumn label="功效满意度" :render-header="renderHeader">
				<template slot-scope="{ row }">
				 <div
				   class="hand"
				   @click="clickBtn(row,'gongxiaomanyi')"
				 >{{row.gongxiaomanyi}}</div>
			   </template>

			</ElTableColumn>

      <ElTableColumn label="总成交数" prop="cj_customer" />
      <!-- <ElTableColumn label="保有顾客" prop="baoyou_customer" /> -->
      <ElTableColumn label="保有顾客" >
				<template slot-scope="{ row }">
				 <div
          v-if="row.baoyou_customer != '——'"
				   class="hand"
				   @click="clickCJBtn('保有顾客',row,'','')"
				 >{{row.baoyou_customer}}</div>
         <div v-else>{{row.baoyou_customer}}</div>
			  </template>

			</ElTableColumn>

      <ElTableColumn label="保有率" prop="rate_baoyou" />

      <ElTableColumn label="流失顾客" >
				<template slot-scope="{ row }">
				 <div
           v-if="row.lost_customer != '——'"
				   class="hand"
				   @click="clickCJBtn('流失顾客',row,'','')"
				 >{{row.lost_customer}}</div>
         <div v-else>{{row.lost_customer}}</div>
			  </template>
			</ElTableColumn>

      <ElTableColumn label="流失优案" >
				<template slot-scope="{ row }">
				 <div
           v-if="row.lost_case != '——'"
				   class="hand"
				   @click="clickCJBtn('流失优案',row,'','')"
				 >{{row.lost_case}}</div>
         <div v-else>{{row.lost_case}}</div>
			  </template>
			</ElTableColumn>

      <ElTableColumn label="月初预警" >
				<template slot-scope="{ row }">
				 <div
           v-if="row.warn_customer != '——'"
				   class="hand"
				   @click="clickCJBtn('流失预警',row,search.start_time,'')"
				 >{{row.warn_customer}}</div>
         <div v-else>{{row.warn_customer}}</div>
			  </template>
			</ElTableColumn>

      <ElTableColumn label="当月回流" width='80'>
				<template slot-scope="{ row }">
				 <div
           v-if="row.back_customer != '——'"
				   class="hand"
				   @click="clickCJBtn('回流顾客',row,search.start_time,search.end_time)"
				 >{{row.back_customer}}</div>
         <div v-else>{{row.back_customer}}</div>
			  </template>
			</ElTableColumn>

      <ElTableColumn label="预警回流率" prop="rate_back"  width="100"/>

      <!-- <ElTableColumn label="标记优案" prop="tag_customer" /> -->

        <ElTableColumn label="预存结余" prop="money" width="100" :render-header="renderHeader" />
        <ElTableColumn label="本月消耗" prop="consumeMoney" :render-header="renderHeader" />
        <ElTableColumn label="消耗占比（相对上月预存）" prop="consumeRate" width="120" :render-header="renderHeader" />
          </el-table>
          <!-- <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getLastDay } from '@/utils';
import pagination from '@/components/Pagination'
import { handleExportExcel } from '@/vendor/handleExportExcel';
import { readAdminShopStatistics } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  type = "";
  cj_type = "0";
}
class Options {
  type = [
      {
        id: "1",
        name: "直营"
      },
      {
        id: "2",
        name: "加盟"
      },
      {
        id: "3",
        name: "直管"
      }
    ];
}
export default {
  name: 'OrderList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      options: new Options(), //选项
      list: [], // 数据展示
      loading:false,
      exportS: false,
      value1: false,
    }
  },
  computed: {
      tableData() {
        const res = this.list.reduce((res, item) => {
          item.maxnum = 0;
          if (item.list && item.list.length > 0) {
            const goods = item.list.map(goods => Object.assign({}, item, goods));
            goods[0].maxnum = item.list.length;
            res = res.concat(goods);
          } else {
            res.push(item);
          }
          return res;
        }, []);
        return res;
      }
    },


  mounted () {
     let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01'
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
  },
  methods: {
     getList(option) {
       this.loading = true
      if (option === "search") this.page = new Page();
      readAdminShopStatistics({ ...this.search, ...this.page }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
        this.loading = false
      });
    },
    clickBtn(row,type){
      //console.log(this.$router);
      const query = {
		 type: type == "popularize"? 2 : type == "developRate"?1 : 3,
        //type: type == "popularize"?2:1,
        shopId: row.shopId,
        start_time: this.search.start_time ,
        end_time: this.search.end_time,
      };
	  //console.log(query);
      this.$router.push({ name: "业务分析数据", query });
    },
    clickCJBtn(routerName,row,start_time,end_time){

      const query = {
          shop_id: row.shopId,
          start_time: start_time,
          end_time:end_time,
          type: "back",
      };

      // console.log(query);
      // return;
      // console.log(routerName);
      // console.log(query);
      // return;
      this.$router.push({ name: routerName, query });
      // let route = this.$router.resolve({ name: routerName, query });
      // window.open(route.href, '_blank');
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.maxnum,
          colspan: 1
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    cellStyle({row, column, rowIndex, columnIndex}){
        if(columnIndex <= 1){
            return 'background:#CCFFFF;text-align:center'
        }
        if(columnIndex > 1 && columnIndex <= 4){
            return 'background:#FFCC99;text-align:center'
        }

        if(columnIndex >= 5 && columnIndex <= 12 ){
            return 'background:#FFFF99;text-align:center'
        }
        if(columnIndex >= 13 ){
            return 'background:#D1FAD1;text-align:center'
        }

        return '';
      },
      rowClass({row, column, rowIndex, columnIndex}){
        return 'background:#E9EDEE;text-align:center'

      },
   // 导出表格
   async exportExcel() {
     this.exportS = true
     const { data } = await readAdminShopStatistics({
        ...this.search, ...this.page,export:1
     });
     handleExportExcel(
       [
         '市场名称',
         '店面名称',
         '项目普及率',
         '疤痘二次开发率',
		  '功效满意度',
		  '总成交数',
		  '保有顾客',
		  '保有率',
		  '流失顾客',
		  '流失优案',
		  '月初预警',
		  '当月回流',
		  '预警回流率',
		  '预存结余',
		  '本月消耗',
		  '消耗占比（相对上月预存）',
       ],
       [
           'marketName',
           'shopName',
           'popularityRate',
           'developRate',
			'gongxiaomanyi',
			'cj_customer',
			'baoyou_customer',

			'rate_baoyou',
			'lost_customer',
			'lost_case',
			'warn_customer',
			'back_customer',
			'rate_back',
			'money',
			'consumeMoney',
			'consumeRate',
		   ],
		   data.list,
		   '业务分析报表'
		 );
		 this.exportS = false
	   },

     changeCJTime(e){
      if(e){
        // console.log('正确');
        this.search.cj_type = '1';
      }
      if(!e){
        // console.log('错误');
        this.search.cj_type = '0';
      }

      console.log(this.search);
      // return
      this.getList('search');
    },
    renderHeader(h, {column}) {
      switch(column.label)
      {
        case "项目普及率":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '项目普及率:A月成交的疤痕新单客户在A+4月中有再次消费项目且金额大于等于500（不包含A月的疤痕新单金额）',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "疤痕二次开发率":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '疤痕二次开发率:A月成交的疤痕新单客户在A+4月中有再次消费疤痕且金额大于等于500（不包含A月的疤痕新单金额）',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "功效满意度":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '功效满意度：A月成交的疤痕新单客户在A+4月中有再次消费疤痕且金额大于等于500（不包含A月的疤痕新单金额）或 该客户被评选为优秀案例 或 该客户有转介绍新客户成交新单金额大于等于500 三个条件满足一个',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "预存结余":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '预存结余：截至本月初门店累计的客户总预存金额合计',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "本月消耗":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '本月消耗',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "消耗占比（相对上月预存）":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '消耗占比（相对上月预存）',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.btnBox {
  text-align: right;
}
.hand {
  cursor: pointer;
}
</style>
